export default {
  title: {
    id: 'express_replacement_details_title',
    defaultMessage: 'What’s Express Replacement',
  },
  description: {
    id: 'express_replacement_details_description',
    defaultMessage:
      'Keep using the original device while we ship your replacement. Then, keep your replacement and send back the original.',
  },
  informationSectionTitle: {
    id: 'express_replacement_details_information_section_title',
    defaultMessage: 'How does it work?',
  },
  replacementOrderSectionTitle: {
    id: 'express_replacement_details_order_section_title',
    defaultMessage: 'Your replacement details',
  },
  faqCta: {
    id: 'express_replacement_details_faq_cta',
    defaultMessage: 'View FAQs',
  },
  getStartedCta: {
    id: 'express_replacement_details_get_started_cta',
    defaultMessage: 'Get started',
  },
}
