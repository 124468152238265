<template>
  <RevCard
    class="flex flex-row p-16"
    data-qa="replacement-order"
    data-test="replacement-order"
  >
    <RevIllustration
      v-if="image.href"
      :alt="title"
      data-test="replacement-order-picture"
      :height="128"
      :src="image.href"
      :width="128"
    />
    <div class="ml-8">
      <h3 class="body-1-bold" data-test="replacement-order-title">
        {{ title }}
      </h3>
      <p
        v-if="isAnUpgrade"
        class="text-static-default-low body-2"
        data-test="replacement-order-grade"
      >
        {{
          i18n(translations.replacementOrderUpgrade, {
            condition: upgradeWording[grade],
          })
        }}
      </p>
      <FormattedMessage
        class="text-static-default-low body-2"
        data-test="replacement-order-seller"
        :definition="translations.replacementOrderSeller"
      >
        <template #link>
          <RevLink target="_blank" :to="seller.shopLink">
            {{ seller.company }}
          </RevLink>
        </template>
      </FormattedMessage>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import type { LinkExternal, LinkInternal } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import type { ExpressReplacementProductGrade } from '~/api/express-replacement/types'

import translations from './XrReplacementOrderDetailsCard.translations'

interface ReplacementOrderCardProps {
  title: string
  image: LinkExternal
  grade: ExpressReplacementProductGrade
  seller: {
    company: string
    shopLink: LinkInternal
  }
  isAnUpgrade?: boolean
}

defineProps<ReplacementOrderCardProps>()

const i18n = useI18n()

const upgradeWording = {
  ECO: i18n(translations.gradeEco),
  VERY_GOOD: i18n(translations.gradeVeryGood),
  FLAWLESS: i18n(translations.gradeFlawless),
  PREMIUM: i18n(translations.gradePremium),
}
</script>
