import { useSalesCustomerCareLogger } from '~/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

export const isConfigDefined = (variable: string, name: string) => {
  const { logFeatureError } = useSalesCustomerCareLogger()

  if (!variable || variable === 'unknown') {
    logFeatureError({
      errorTitle: `Environment variable ${name} doesn't contain a valid value or is undefined`,
      errorDetail: {
        name,
        value: variable,
      },
    })
  }

  return variable
}
