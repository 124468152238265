export default {
  replacementOrderUpgrade: {
    id: 'express_replacement_details_replacement_order_upgrade',
    defaultMessage: 'Free upgrade to {condition} condition',
  },
  replacementOrderSeller: {
    id: 'express_replacement_details_replacement_order_seller',
    defaultMessage: 'Provided by {link, html}',
  },
  gradeEco: {
    id: 'express_replacement_details_grade_eco',
    defaultMessage: 'fair',
  },
  gradeVeryGood: {
    id: 'express_replacement_details_grade_very_good',
    defaultMessage: 'good',
  },
  gradeFlawless: {
    id: 'express_replacement_details_grade_flawless',
    defaultMessage: 'excellent',
  },
  gradePremium: {
    id: 'express_replacement_details_grade_premium',
    defaultMessage: 'premium',
  },
}
