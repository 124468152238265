export default {
  informationSectionCreditCardTitle: {
    id: 'express_replacement_details_information_section_credit_card_title',
    defaultMessage: 'Confirm your credit card',
  },
  informationSectionCreditCardDescription: {
    id: 'express_replacement_details_information_section_credit_card_description',
    defaultMessage:
      "You won't be charged, we'll just check that your credit card is valid.",
  },
  informationSectionDeliveryDateTitle: {
    id: 'express_replacement_details_information_section_delivery_date_title',
    defaultMessage: 'Get your replacement device by {date, html}',
  },
  informationSectionDeliveryDateDescription: {
    id: 'express_replacement_details_information_section_delivery_date_description',
    defaultMessage: 'Free shipping.',
  },
  informationSectionReturnDeadlineTitle: {
    id: 'express_replacement_details_information_section_return_deadline_title',
    defaultMessage: 'Return the original by {deadline, html}',
  },
  informationSectionReturnDeadlineDescription: {
    id: 'express_replacement_details_information_section_return_deadline_description',
    defaultMessage:
      'When the seller gets the original device, they’ll inspect it within 2 business days.',
  },
}
