<template>
  <RevCard
    class="space-y-24 p-24"
    data-qa="explanation-block"
    data-test="explanation-block"
  >
    <XrInformationRow
      :description="i18n(translations.informationSectionCreditCardDescription)"
      :title="i18n(translations.informationSectionCreditCardTitle)"
    >
      <template #icon>
        <IconCreditCard />
      </template>
    </XrInformationRow>
    <XrInformationRow
      :description="
        i18n(translations.informationSectionDeliveryDateDescription)
      "
    >
      <template #icon>
        <IconTruckExpress />
      </template>
      <template #title>
        <FormattedMessage
          class="body-1-bold"
          data-qa="delivery-date"
          data-test="delivery-date"
          :definition="translations.informationSectionDeliveryDateTitle"
        >
          <template #date>
            {{ i18n.date(new Date(deliveryDate), DATE_FORMAT) }}
          </template>
        </FormattedMessage>
      </template>
    </XrInformationRow>
    <XrInformationRow
      :description="
        i18n(translations.informationSectionReturnDeadlineDescription)
      "
    >
      <template #icon>
        <IconPackage />
      </template>
      <template #title>
        <FormattedMessage
          class="body-1-bold"
          data-qa="deadline-date"
          data-test="deadline-date"
          :definition="translations.informationSectionReturnDeadlineTitle"
        >
          <template #deadline>
            {{ i18n.date(new Date(deadline), DATE_FORMAT) }}
          </template>
        </FormattedMessage>
      </template>
    </XrInformationRow>
  </RevCard>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { IconCreditCard } from '@ds/icons/IconCreditCard'
import { IconPackage } from '@ds/icons/IconPackage'
import { IconTruckExpress } from '@ds/icons/IconTruckExpress'

import XrInformationRow from '~/scopes/customer-care/resolution/components/ExpressReplacement/details/XrInformationRow/XrInformationRow.vue'
import { DATE_FORMAT } from '~/scopes/customer-care/resolution/pages/express-replacement/details/ExpressReplacementDetails.constants'

import translations from './XrInformationSection.translations'

interface ExplanationBlockProps {
  deliveryDate: string
  deadline: string
}

defineProps<ExplanationBlockProps>()

const i18n = useI18n()
</script>
