<template>
  <div>
    <RevIllustration
      alt="Express Replacement illustration"
      :height="200"
      image-class-names="w-full object-cover rounded-lg max-h-200 my-24"
      src="/img/customer-care/resolution-flow/xr.jpeg"
      :width="327"
    />

    <div class="mb-32">
      <h1 class="heading-2 mb-16">
        {{ i18n(translations.title) }}
        <sup class="text-static-default-hi caption-bold">BETA</sup> ?
      </h1>
      <p class="body-1">{{ i18n(translations.description) }}</p>
    </div>

    <div class="mb-32">
      <h2 class="heading-3 mb-12">
        {{ i18n(translations.informationSectionTitle) }}
      </h2>
      <XrInformationSection
        v-if="data"
        :deadline="data.initialOrder.returnWindow"
        :delivery-date="data.replacementOrder.deliveryDatesWindow.min"
      />
    </div>

    <div class="mb-24">
      <h2 class="heading-3 mb-12">
        {{ i18n(translations.replacementOrderSectionTitle) }}
      </h2>
      <XrReplacementOrderDetailsCard
        v-if="data?.replacementOrder"
        :grade="data.replacementOrder.grade"
        :image="data.replacementOrder.product.image"
        :isAnUpgrade
        :seller="data.replacementOrder.seller"
        :title="data.replacementOrder.product.title"
      />
    </div>

    <RevButton
      class="hidden md:mb-24 md:inline-block"
      data-qa="continue-cta-desktop"
      data-test="continue-cta-desktop"
      full-width="always"
      variant="primary"
      @click="goToNextPage"
      >{{ i18n(translations.getStartedCta) }}</RevButton
    >
    <RevButton
      class="mb-72"
      data-qa="faq-cta"
      data-test="faq-cta"
      full-width="always"
      variant="secondary"
      @click="goToFaq"
      >{{ i18n(translations.faqCta) }}</RevButton
    >
    <RevStickyBar class="block md:hidden">
      <RevButton
        data-qa="continue-cta-mobile"
        data-test="continue-cta-mobile"
        full-width="always"
        variant="primary"
        @click="goToNextPage"
        >{{ i18n(translations.getStartedCta) }}</RevButton
      >
    </RevStickyBar>
  </div>
</template>

<script setup lang="ts">
import {
  navigateTo,
  useAsyncData,
  useRouteLocationWithLocale,
  useRuntimeConfig,
  useZendeskFaqBaseUrl,
} from '#imports'
import { computed, inject, onMounted, toRaw } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import { getAsyncDataKey } from '@backmarket/nuxt-module-http-v2/getAsyncDataKey'
import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevStickyBar } from '@ds/components/StickyBar'

import { isConfigDefined } from '~/scopes/care-commons/utils/isConfigDefined'
import XrInformationSection from '~/scopes/customer-care/resolution/components/ExpressReplacement/details/XrInformationSection/XrInformationSection.vue'
import XrReplacementOrderDetailsCard from '~/scopes/customer-care/resolution/components/ExpressReplacement/details/XrReplacementOrderDetailsCard/XrReplacementOrderDetailsCard.vue'
import { ResolutionFlowStateMachineKey } from '~/scopes/customer-care/resolution/symbols'
import { CUSTOMER_CARE } from '~/scopes/customer-care/route-names'

import translations from './ExpressReplacementDetails.translations'

const resolutionFlowStateMachine = inject(ResolutionFlowStateMachineKey)

const i18n = useI18n()
const { ZENDESK_EXPRESS_REPLACEMENT_FAQ_ARTICLE } = useRuntimeConfig().public
const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const $httpFetch = useHttpFetch()
const getXrDataKey = getAsyncDataKey('getXrData', {
  orderlineId: resolutionFlowStateMachine?.state.value.context.publicId,
})

const { data, error } = await useAsyncData(
  getXrDataKey,
  () =>
    $httpFetch('/express-replacement/funnel/:orderlineId', {
      pathParams: {
        orderlineId: String(
          resolutionFlowStateMachine?.state.value.context.publicId,
        ),
      },
    }),
  {
    immediate: !resolutionFlowStateMachine?.state.value.context.xrData,
    default: () => resolutionFlowStateMachine?.state.value.context.xrData,
  },
)

onMounted(() => {
  if (error.value) {
    resolutionFlowStateMachine?.send('ERROR')
  }
})

if (data.value && !resolutionFlowStateMachine?.state.value.context.xrData) {
  resolutionFlowStateMachine?.send({
    type: 'SET_XR_DATA',
    data: toRaw(data.value),
  })
}
const expressReplacementFaqArticleLink = toNuxtLink({
  type: 'external',
  href: `${zendeskFaqBaseUrl}/articles/${isConfigDefined(ZENDESK_EXPRESS_REPLACEMENT_FAQ_ARTICLE, 'ZENDESK_EXPRESS_REPLACEMENT_FAQ_ARTICLE')}`,
})

const isAnUpgrade = computed(
  () => data.value?.replacementOrder.grade !== data.value?.initialOrder.grade,
)

const goToNextPage = () =>
  navigateTo(
    resolveLocalizedRoute({
      name: CUSTOMER_CARE.RESOLUTION.EXPRESS_REPLACEMENT.SHIPPING,
    }) as RouteLocationRaw,
  )

const goToFaq = () => {
  navigateTo(expressReplacementFaqArticleLink, {
    external: true,
  })
}
</script>
