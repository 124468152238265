<template>
  <div class="py-32 md:py-56">
    <h2 class="heading-2 mb-32">
      {{ title || i18n(translations.title) }}
    </h2>

    <div
      class="grid-standard gap-y-24 md:gap-y-48"
      :class="{
        'gradient-mask-b-20': categoryTree.length > 4 && !displayAllCategories,
      }"
    >
      <TransitionGroup
        appear
        enter-active-class="transition-opacity duration-300 ease-in"
        enter-from-class="opacity-0"
        leave-active-class="transition-opacity duration-100 ease-in"
      >
        <ul
          v-for="(category, index) in categoryTree"
          v-show="index < 4 || displayAllCategories"
          :key="`footerCategory${category.id}`"
          class="list-none px-0 md:col-span-2 md:pt-0 lg:col-span-3"
        >
          <li class="mb-16">
            <RevLink
              :to="category.link.href"
              :underlined="false"
              @click="sendMarketingData(category.name)"
            >
              <span class="flex">
                <DynamicIcon
                  v-if="category.icon"
                  v-bind="category.icon"
                  class="mr-8"
                />

                <span class="body-1-bold">
                  {{ category.name }}
                </span>
              </span>
            </RevLink>
          </li>
          <li v-if="category.subCategories.length">
            <ul class="flex list-none flex-col gap-4">
              <li
                v-for="subCategory in category.subCategories"
                :key="`footerLink${subCategory.id}`"
              >
                <span class="body-2">
                  <RevLink
                    class="text-action-default-low font-weight-body-2"
                    :to="subCategory.link.href"
                    :underlined="false"
                    @click="sendMarketingData(subCategory.name)"
                  >
                    {{ subCategory.name }}
                  </RevLink>
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </TransitionGroup>
    </div>

    <div
      v-if="categoryTree.length > 4"
      class="mr-12 flex justify-center md:mt-16"
    >
      <RevButton
        :icon="displayAllCategories ? IconChevronUp : IconChevronDown"
        size="small"
        variant="primary"
        @click="displayAllCategories = !displayAllCategories"
      >
        {{
          i18n(
            displayAllCategories
              ? translations.hideCategories
              : translations.seeAllCategories,
          )
        }}
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import type { CategoryTreeProps } from '@backmarket/http-api/src/api-specs-content/models/category-tree'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevLink } from '@ds/components/Link'
import { IconChevronDown } from '@ds/icons/IconChevronDown'
import { IconChevronUp } from '@ds/icons/IconChevronUp'

import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import DynamicIcon from '../../shared-components/DynamicIcon/DynamicIcon.vue'

import translations from './CategoryTree.translations'

const i18n = useI18n()

type Props = ContentBlockProps & CategoryTreeProps

const props = defineProps<Props>()

const { trackClick } = useTracking()

const displayAllCategories = ref<boolean>(false)

function sendMarketingData(displayName: string) {
  trackClick({ ...props.tracking, ...{ value: { articleName: displayName } } })
}
</script>
